<template>
    <div id="features">
      <div class="container-fluid">
        <!-- Add Edit Section -->
        <!-- @submit="onSubmit" @reset="onReset" -->
        <div class="add-section" v-if="canAccess(['store-feature','update-feature'])">
          <b-modal id="features-modal" hide-header-close @hidden="closeModal" :cancel-title="$t('close')" :ok-title="$t('save')" :title="$t('sidebar.feature')" @ok="submit">
            <b-form class="d-grid gap-5">
              <div class="feild">
                <label for="">{{ $t('ar-name') }}</label>
                <b-form-input
                  type="text"
                  placeholder=""
                  v-model="payload.ar_name"
                  required
                ></b-form-input>
              </div>
              <div class="feild">
                <label for="">{{ $t('en-name') }}</label>
                <b-form-input
                  type="text"
                  placeholder=""
                  v-model="payload.en_name"
                  required
                ></b-form-input>
              </div>
              <div class="feild">
                <label for="">{{ $t('kr-name') }}</label>
                <b-form-input
                  type="text"
                  v-model="payload.kr_name"
                  required
                ></b-form-input>
                </div>
            </b-form>
<!--            <div class="feild">-->
<!--                <label for="">Icon</label>-->
<!--                <v-select v-model="payload.icon" :options="icons" label="title" :reduce="(option)=>option.icon">-->
<!--                  <template v-slot:option="option">-->
<!--                    <i :class="option.icon"></i>-->
<!--                    <span class="mx-1">-->
<!--                      {{ option.title }}-->
<!--                    </span>-->
<!--                  </template>-->
<!--                </v-select>-->
<!--              </div>-->
          </b-modal>
        </div>
        <div class="add-sub-section">
          <b-modal id="features-sub-modal" hide-footer hide-header-close @hidden="closeModal" :cancel-title="$t('close')" :ok-title="$t('save')" :title="$t('sidebar.feature')" :ok-disabled="false" size="lg">
            <b-form class="">
              <b-row>
                <b-col sm="12" md="4">
                  <div class="feild">
                    <label for="">{{ $t('ar-name') }}</label>
                    <b-form-input
                      type="text"
                      v-model="payload.ar_name"
                      required
                    ></b-form-input>
                  </div>
                </b-col>
                <b-col sm="12" md="4">
                  <div class="feild">
                    <label for="">{{ $t('en-name') }}</label>
                    <b-form-input
                      type="text"
                      v-model="payload.en_name"
                      required
                    ></b-form-input>
                  </div>
                </b-col>
                <b-col sm="12" md="4">
                  <div class="feild">
                    <label for="">{{ $t('kr-name') }}</label>
                    <b-form-input
                      type="text"
                      v-model="payload.kr_name"
                      required
                    ></b-form-input>
                  </div>
                </b-col>
<!--                <b-col sm="12" md="4">-->
<!--                  <div class="feild">-->
<!--                    <label for="">Icon</label>-->
<!--                    <v-select v-model="payload.icon" placeholder="Select Service Icon" :options="icons" label="title" :reduce="(option)=>option.icon">-->
<!--                      <template v-slot:option="option">-->
<!--                        <i :class="option.icon"></i>-->
<!--                        <span class="mx-1">-->
<!--                          {{ option.title }}-->
<!--                        </span>-->
<!--                      </template>-->
<!--                    </v-select>-->
<!--                  </div>-->
<!--                </b-col>-->
                <b-col cols="12">
                  <div class="controll py-3 d-flex justify-content-end">
                    <b-button variant=" iq-bg-primary"  size="md" @click="submit">{{ $t('add') }}</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
            <div class="featurs-table">
              <b-table responsive :items="showFeatures.features" :fields="subFields" class="mb-0 table-borderless">
                <template v-slot:cell(actions)="data">
                    <div class="actions-list d-flex align-items-center">
                      <b-button v-b-tooltip.top="$t('remove')" variant=" iq-bg-danger"  size="md" @click="remove(data.item.id)"><i class="ri-delete-bin-line p-0"></i></b-button>
                    </div>
                  </template>
              </b-table>
            </div>
          </b-modal>
        </div>
        <!-- Filters Section -->
        <div class="filters-section">
          <iq-card class="w-100">
            <template v-slot:body>
              <div class="filter-container" @change="filtering">
                <b-form-input
                  type="text"
                  :placeholder="$t('filter-name')"
                  v-model="filterData[`${$i18n.locale}_name`]"
                  required
                ></b-form-input>
              </div>
            </template>
          </iq-card>
        </div>
        <!-- Tabel Section -->
        <div class="table-sections">
          <iq-card>
            <template v-slot:cell(icon)="data">
                    <i :class="data.item.icon"></i>
              </template>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('feature-list') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button v-if="canAccess(['store-feature'])" variant="primary" @click="addInit" size="sm">{{ $t('add') }}</b-button>
            </template>
            <template v-slot:body>
              <b-table responsive v-if="canAccess(['get-feature','update-feature','delete-feature'])" :items="features.data" :fields="fields" class="mb-0 table-borderless">
                <template v-slot:cell(actions)="data">
                    <div class="actions-list d-flex align-items-center">
                      <b-button v-b-tooltip.top="$t('add-sub-feature')" variant=" iq-bg-primary"  size="md" @click="subInit(data.item)"><i class="fas fa-hand-holding p-0"></i></b-button>
                      <b-button v-if="canAccess(['update-feature'])" v-b-tooltip.top="$t('edit')" variant=" iq-bg-success" @click="update(data.item)"  size="md"><i class="ri-ball-pen-fill p-0"></i></b-button>
                      <!-- <b-button v-if="canAccess(['delete-feature'])" v-b-tooltip.top="$t('remove')" variant=" iq-bg-danger"  size="md" @click="remove(data.item.id)"><i class="ri-delete-bin-line p-0"></i></b-button> -->
                    </div>
                  </template>
              </b-table>
            </template>
          </iq-card>
        </div>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination
                  v-model="filterData.page"
                  :total-rows="features.total"
                  :per-page="features.per_page"
              ></b-pagination>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  name: 'Features',
  data () {
    return {
      filterData: {
        page: 1,
        ar_name: '',
        en_name: '',
        kr_name: ''
      },
      payload: {
        ar_name: '',
        en_name: '',
        kr_name: '',
        feature_id: ''
      },
      showFeatures: {},
      icons: [
        {
          icon: 'fas fa-utensils',
          title: 'Food'
        },
        {
          icon: 'fas fa-glass-martini-alt',
          title: 'Bar'
        },
        {
          icon: 'fas fa-dumbbell',
          title: 'Gym'
        },
        {
          icon: 'fas fa-swimmer',
          title: 'Pool'
        },
        {
          icon: 'fas fa-hot-tub',
          title: 'Sauna'
        },
        {
          icon: 'fas fa-concierge-bell',
          title: 'Room Service'
        },
        {
          icon: 'fas fa-gamepad',
          title: 'Game Room'
        },
        {
          icon: 'fas fa-parking',
          title: 'Parking'
        }
      ]
    }
  },
  computed: {
    fields () {
      return [
        // {
        //   label: 'Icon',
        //   key: 'icon'
        // },
        {
          label: this.$t('name'),
          key: `${this.$i18n.locale}_name`
        },
        {
          label: this.$t('created-date'),
          key: 'created_at'
        },
        {
          label: this.$t('created-by'),
          key: 'created_by.name'
        },
        {
          label: this.$t('updated-date'),
          key: 'updated_at'
        },
        {
          label: this.$t('updated-by'),
          key: 'updated_by.name'
        },
        {
          label: this.$t('actions'),
          key: 'actions'
        }
      ]
    },
    subFields () {
      return [
        // {
        //   label: 'Icon',
        //   key: 'icon'
        // },
        {
          label: this.$t('name'),
          key: `${this.$i18n.locale}_name`
        },
        {
          label: this.$t('created-date'),
          key: 'created_at'
        },
        {
          label: this.$t('created-by'),
          key: 'created_by.name'
        },
        {
          label: this.$t('actions'),
          key: 'actions'
        }
      ]
    }

  },
  methods: {
    filtering () {
      this.filterData.page = 1
      this.getFeatures(this.filterData)
    },
    addInit () {
      this.$bvModal.show('features-modal')
    },
    subInit (data) {
      this.$bvModal.show('features-sub-modal')
      this.showFeatures = data
      this.payload.feature_id = data.id
    },
    update (data) {
      Object.assign(this.payload, data)
      this.$bvModal.show('features-modal')
    },
    remove (id) {
      this.confermMsg(() => {
        this.removeFeatures(id)
      })
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      if (!this.payload.id) {
        this.addFeatures(this.payload).then(() => {
          this.$bvModal.hide('features-modal')
          this.$bvModal.hide('features-sub-modal')
          this.payload = this.resetObject(this.payload)
          this.getFeatures()
        })
      } else {
        this.updateFeatures(this.payload).then(() => {
          this.payload = this.resetObject(this.payload)
          this.$bvModal.hide('features-modal')
          this.$bvModal.hide('features-sub-modal')
          this.getFeatures()
        })
      }
    },
    closeModal (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.payload = this.resetObject(this.payload)
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getFeatures(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
    this.getFeatures()
  }
}
</script>
